

.react-time-picker__inputGroup__input,
.react-time-picker__button__icon {
  color: white;
  fill: white;
}

.react-time-picker__clear-button__icon {
  stroke: white;
}